<template>
  <div class="store">

      <van-floating-bubble  v-if="couponSign" icon="http://res.hanyuanyuwen.com/image/shop/coupon2.png" @click="getCoupons" v-model:offset="offset"/>


    <van-search
      v-model="value"
      show-action
      shape="round"
      background="#fff"
      placeholder="请输入商品关键词"
      @update:model-value="onSearch"
      class="van-hairline--bottom"
    >
      <template #action>
        <van-icon name="cart-o" color="rgb(242, 85, 0)" size="34" @click="showCart" v-if="quantity < 1" class="cart"/>
        <van-icon name="cart-o" color="rgb(242, 85, 0)" size="34" @click="showCart" v-else :badge="quantity" class="cart"/>
      </template>
    </van-search>
    <!--<van-notice-bar left-icon="volume-o" :scrollable="false" mode="closeable">米袋虽空——樱花开哉！</van-notice-bar>-->
    <van-tabs  v-model:active="active" @change="onChangeTab" :ellipsis="false" sticky animated >
      <van-tab  v-for="(item,index) in categoryList" :key="index">
        <template #title>
          <div >{{item.name}}</div>
        </template>
      </van-tab>
    </van-tabs>
    <div class="content">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
        @offset="10"
        class="goods-list"
      >
        <div class="goods" v-for="(item, index) in list" :key="index" @click="goto(item.id)">
          <div class="cover">
            <van-image width="100%" height="100%" fit="cover" :src="item.coverImg"/>
          </div>
          <div class="intro">
            <div class="marquee">
              <div class="marquee-wrap">
                <div class="marquee-content ">
                  {{item.name}}
                </div>
              </div>
            </div>
            <div class="sell">
              <div class="price">￥{{item.marketPrice}}</div>
              <div class="amount">{{item.buy}} 人买过</div>
            </div>
            <div class="vip">
              <span>￥{{item.vipPrice}}</span>
              <img style="width: 25px;height: 25px;" src="../assets/images/vip.png"/>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <nav-bar/>
  </div>
</template>

<script>
  import { ref, reactive, toRefs,onActivated,onMounted } from 'vue';
  import { showToast } from 'vant';
  import { useRouter } from 'vue-router';
  import navBar from "@/components/NavBar";
  import {getGoodsList,categorys } from '@/service/goods'
  import { myCarNum } from '@/service/order'
  import { noGetCoupons } from '@/service/user'

  export default {
    name: "Store",
    components: {
      navBar,
    },
    setup() {
      const fetchData=async()=>{
        if(token){
          myCarNum(token.userId).then(res =>{
            quantity.value=res.data.data;
          })

          noGetCoupons(token.userId).then(res =>{
            if(res.data.status>0){
              couponSign.value=true;
            }else{
              couponSign.value=false;
            }

          })
        }
      };
      const router = useRouter();
      const storage= localStorage.getItem("token-hyxt");
      const token=JSON.parse(storage);
      const couponSign=ref(false);
      const value = ref('');
      const offset = ref({ x: 280, y: 450 });
      const quantity = ref(0);  // 购物车商品数量
      const state = reactive({
        active: 0,
        list: [],
        type: "",
        page: 1,
        loading: false,
        finished: false,
        finishedText:"",
        categoryList:[]
      });
      const onSearch = () => {
        loadData();
      };
      const onChangeTab = () => {
        state.page = 1;
        state.list = [];
        state.finished = false;
        state.loading = true;
        onLoad();
      };
      const loadData = async () => {
        let type = "";
        console.log("state.active")
        console.log(state.active)
        if (state.active == 0) {
          type = 1;
        } else if (state.active == 1) {
          type = 2;
        } else if (state.active == 2) {
          type = 3;
        } else if (state.active == 3) {
          type = 4;
        }
        let res = await getGoodsList(type,value.value);
        // console.log("load data list", res.data);
        state.list = res.data.data;
        if(state.list.length < 1) {
          state.finishedText = "暂无数据";
          state.finished = true; //上拉结束
        }else if(state.list.length >= 20) {   //修改位res.data.list.length < 1 适配正式环境
          state.finishedText = "没有更多了";
          state.finished = true; //上拉结束
        } else {
          state.loading = false;
          state.finished = true; //上拉结束
          state.finishedText = "没有更多了";
        }
      }

      const getCoupons=()=>{
        router.push({path:'/getCoupons'});
      }
      onMounted(() => {
       categorys().then(res=>{
         state.categoryList=res.data.data;
         console.log("商品类别")
         console.log(res.data.data)
       })
      })

      onMounted(fetchData);
      onActivated(fetchData);

      // onActivated((()=>{
      //   console.log("购物车数量")
      //   if(token){
      //     myCarNum(token.userId).then(res =>{
      //       quantity.value=res.data.data;
      //     })
      //
      //     noGetCoupons(token.userId).then(res =>{
      //       if(res.data.status>0){
      //         couponSign.value=true;
      //       }
      //
      //     })
      //   }
      // }))

      const onLoad = () => {
        // console.log("on load type:", state.active);
        loadData();
      };
      const showCart = () => {
        showToast ("显示购物车结算");
        router.push("/cart");
      };
      // 到商品详情
      const goto = (goodsId) => {
        router.push({path:'/goodsDetail',query:{goodsId: goodsId}});
      }

      return {
        value,
        quantity,
        ...toRefs(state),
        onSearch,
        onChangeTab,
        onLoad,
        showCart,
        goto,
        getCoupons,
        offset,
        couponSign
      };
    }
  };
</script>

<style lang="scss" scoped>
  .store {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .van-search {
      .cart {
        margin-right: 18px;
      }
    }
    .content {
      flex: 1;
      overflow:scroll;
      .goods-list {
        margin: 12px 8px;
        padding-bottom: 90px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .goods {
          width: 48%;
          height: 470px;
          border: 1px solid #cdcdcd;
          border-radius: 12px;
          margin-bottom: 12px;
          background-color: #fff;
          .cover {
            height: 320px;
          }
          .intro {
            height: 113px;
            padding: 14px 12px 8px 12px;
            .name {
              height: 65px;
              font-size: 26px;
              line-height: 36px;
              font-weight: 500;
            }
            .marquee {
              overflow: hidden;
              .marquee-wrap {
                width: 50px;
                animation: marquee-wrap 4s infinite linear;
              }
              .marquee-content {
                font-size: 26px;
                float: left;
                white-space: nowrap;
                min-width: 50px;
                animation: marquee-content 4s infinite linear;
              }
            }
            @keyframes marquee-wrap {
              0%,
              30% {
                transform: translateX(0);
              }
              70%,
              100% {
                transform: translateX(100%);
              }
            }

            @keyframes marquee-content {
              0%,
              30% {
                transform: translateX(0);
              }
              70%,
              100% {
                transform: translateX(-100%);
              }
            }
            .sell {
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              .price {
                font-size: 32px;
                color: rgb(242, 85, 0);
              }
              .amount {
                font-size: 20px;
                color: #707070;
              }
            }
            .vip{
              display: flex;
              width: 45%;
              justify-content:space-between ;
              align-items: center;
              font-size: 32px;
              color: rgb(242, 85, 0);
            }
          }
        }
      }
    }
  }
</style>
